import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1b27ce86"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/codingci/tools/jenkins_home/workspace/3440258-cci-46129907-392501/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b27ce86')) {
      api.createRecord('1b27ce86', component.options)
    } else {
      api.reload('1b27ce86', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=1b27ce86", function () {
      api.rerender('1b27ce86', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/CaseDetail/pages/Information/zhishishu/views/wodezhishi/index.vue"
export default component.exports
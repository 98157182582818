<template>
  <div>
    <xi-tong-zhi-shi />
  </div>
</template>

<script>
import XiTongZhiShi from '../xitongzhishi'
export default {
  components: {
    XiTongZhiShi
  }
}
</script>
